import styled from "styled-components"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.div`
  background: ${({ theme }) => theme.color.backgroundSectionOne};
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 48px 24px;
  }
`

export const Content = styled.div`
  color: ${({ theme }) => theme.color.black};
  max-width: 900px;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 400px;
  }
`

export const Description = styled.p`
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Quotes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 48px;

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Logo = styled.img`
  width: 124px;
  height: 124px;
`

export const Quote = styled.div``

export const QuoteText = styled.h3`
  font-style: italic;
  line-height: 32px;
`

export const QuoteAuthor = styled.p``
