import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"

import SEO from "../components/seo"
import Launching from "../components/layout/Launching"
import LaunchingSoon from "../components/sections/LaunchingSoon"

const LaunchingSoonPage = ({ data }) => {
  const { markdownRemark } = data
  const {
    title,
    subTitle,
    description,
    quoteTextFirst,
    quoteNameFirst,
    quoteTextSecond,
    quoteNameSecond,
  } = markdownRemark.frontmatter

  // Quotes
  const quotes = [
    { text: quoteTextFirst, author: quoteNameFirst },
    { text: quoteTextSecond, author: quoteNameSecond },
  ]

  // Redirect to home
  useEffect(() => {
    if (process.env.GATSBY_COMING_SOON !== "active") {
      navigate("/")
    }
  }, [])

  if (process.env.GATSBY_COMING_SOON !== "active") {
    return null;
  }

  return (
    <Launching>
      <SEO title={title} description={description} />
      <LaunchingSoon
        title={title}
        subTitle={subTitle}
        description={description}
        quotes={quotes}
      />
    </Launching>
  )
}

export const pageQuery = graphql`
  query LaunchQuery($locale: String) {
    markdownRemark(
      frontmatter: { lang: { eq: $locale }, id: { eq: "launch" } }
    ) {
      frontmatter {
        slug
        title
        subTitle
        description
        quoteTextFirst
        quoteNameFirst
        quoteTextSecond
        quoteNameSecond
      }
    }
  }
`

export default LaunchingSoonPage
